const HEADER = 'header';

const DESIGN_SYSTEM_COLORS = [
  {
    title: 'White',
    value: 'white',
  },
  {
    title: 'Black',
    value: 'black',
  },
  {
    title: 'Primary - 100',
    value: 'primary-100',
  },
  {
    title: 'Primary - 200',
    value: 'primary-200',
  },
  {
    title: 'Primary - 300',
    value: 'primary-300',
  },
  {
    title: 'Primary - 400',
    value: 'primary-400',
  },
  {
    title: 'Primary - 500',
    value: 'primary-500',
  },
  {
    title: 'Secondary - 100',
    value: 'secondary-100',
  },
  {
    title: 'Secondary - 200',
    value: 'secondary-200',
  },
  {
    title: 'Secondary - 300',
    value: 'secondary-300',
  },
  {
    title: 'Secondary - 400',
    value: 'secondary-400',
  },
  {
    title: 'Secondary - 500',
    value: 'secondary-500',
  },
  {
    title: 'Neutral - 100',
    value: 'neutral-100',
  },
  {
    title: 'Neutral - 200',
    value: 'neutral-200',
  },
  {
    title: 'Neutral - 300',
    value: 'neutral-300',
  },
  {
    title: 'Neutral - 400',
    value: 'neutral-400',
  },
  {
    title: 'Neutral - 500',
    value: 'neutral-500',
  },
  {
    title: 'Neutral - 500',
    value: 'neutral-600',
  },
  {
    title: 'Neutral - 500',
    value: 'neutral-700',
  },
  {
    title: 'Neutral - 500',
    value: 'neutral-800',
  },
  {
    title: 'Neutral - 500',
    value: 'neutral-900',
  },
  {
    title: 'Success - 100',
    value: 'success-100',
  },
  {
    title: 'Success - 200',
    value: 'success-200',
  },
  {
    title: 'Success - 300',
    value: 'success-300',
  },
  {
    title: 'Success - 400',
    value: 'success-400',
  },
  {
    title: 'Success - 500',
    value: 'success-500',
  },
  {
    title: 'Error - 100',
    value: 'error-100',
  },
  {
    title: 'Error - 200',
    value: 'error-200',
  },
  {
    title: 'Error - 300',
    value: 'error-300',
  },
  {
    title: 'Error - 400',
    value: 'error-400',
  },
  {
    title: 'Error - 500',
    value: 'error-500',
  },
  {
    title: 'Warning - 100',
    value: 'warning-100',
  },
  {
    title: 'Warning - 200',
    value: 'warning-200',
  },
  {
    title: 'Warning - 300',
    value: 'warning-300',
  },
  {
    title: 'Warning - 400',
    value: 'warning-400',
  },
  {
    title: 'Warning - 500',
    value: 'warning-500',
  },
];

export default [
  {
    name: 'COLORS_DARK_MODE',
    type: 'bool',
    default: false,
    description: 'Dark mode for navigation bar',
    category: HEADER,
  },
  {
    name: 'HEADER_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'white',
    description: 'Header background color',
    category: HEADER,
  },
  {
    name: 'HEADER_BORDER_COLOR',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'neutral-200',
    description: 'Header border bottom color',
    category: HEADER,
  },
  {
    name: 'HEADER_CREATE_ACCOUNT_BTN_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'primary-300',
    description: 'Create account button color - Background',
    category: HEADER,
  },
  {
    name: 'HEADER_CREATE_ACCOUNT_BTN_TEXT',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'white',
    description: 'Create account button color - Text',
    category: HEADER,
  },
  {
    name: 'HEADER_CREATE_ACCOUNT_BTN_HOVER_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'primary-400',
    description: 'Create account button color - Hover background',
    category: HEADER,
  },
  {
    name: 'HEADER_CREATE_ACCOUNT_BTN_HOVER_TEXT',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'white',
    description: 'Create account button color - Hover text',
    category: HEADER,
  },
  {
    name: 'HEADER_SIGN_IN_BTN_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'white',
    description: 'Sign in link color - background',
    category: HEADER,
  },
  {
    name: 'HEADER_SIGN_IN_BTN_TEXT',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'primary-300',
    description: 'Sign in link color - Text',
    category: HEADER,
  },
  {
    name: 'HEADER_SIGN_IN_BTN_HOVER_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'primary-100',
    description: 'Sign in link color - Hover background',
    category: HEADER,
  },
  {
    name: 'HEADER_SIGN_IN_BTN_HOVER_TEXT',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'primary-300',
    description: 'Sign in link color - Hover text',
    category: HEADER,
  },
  {
    name: 'HEADER_GENERAL_BTN_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'white',
    description: 'General and custom links color - Background',
    category: HEADER,
  },
  {
    name: 'HEADER_GENERAL_BTN_TEXT',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'black',
    description: 'General and custom links color - Text',
    category: HEADER,
  },
  {
    name: 'HEADER_GENERAL_BTN_HOVER_BG',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'neutral-100',
    description: 'General and custom links color - Hover background',
    category: HEADER,
  },
  {
    name: 'HEADER_GENERAL_BTN_HOVER_TEXT',
    type: 'select',
    options: DESIGN_SYSTEM_COLORS,
    default: 'black',
    description: 'General and custom links color - Hover text',
    category: HEADER,
  },
  {
    name: 'CUSTOM_LOGO',
    type: 'image',
    default: '',
    description: 'Custom logo for header',
    category: HEADER,
  },
  {
    name: 'CUSTOM_SOCIAL_LOGO',
    type: 'image',
    default: '',
    description: 'Custom logo for social preview',
    category: HEADER,
  },
  {
    name: 'CUSTOM_FAVICON',
    type: 'image',
    default: '',
    description: 'Custom favicon of the page',
    category: HEADER,
  },
  {
    name: 'CUSTOM_LOGO_LINK',
    type: 'string',
    default: '',
    description: 'Custom link for logo',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_HEIGHT',
    type: 'string',
    default: '50px',
    description: 'Custom header height',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_LOGO_HEIGHT',
    type: 'string',
    default: '35px',
    description: 'Custom header logo height',
    category: HEADER,
  },
  {
    name: 'HEADER_LOGO_POSITION',
    type: 'select',
    options: [
      { title: 'Center', value: 'center' },
      { title: 'Top', value: 'start' },
      { title: 'Bottom', value: 'end' },
    ],
    default: 'center',
    description: 'Logo position',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_LINKS',
    type: 'pairs',
    default: [],
    description: 'Custom header links',
    category: HEADER,
  },
  {
    name: 'PROMOTE_APP_LINK',
    type: 'string',
    default: '',
    description: 'The link to the whitelabels mobile app to be promoted',
    category: HEADER,
  },
  {
    name: 'PROMOTE_APP_IMG',
    type: 'string',
    default: '',
    description:
      'The link to the whitelabels img to be use in promote app header',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_CONTENT',
    type: 'string',
    default: '',
    description: 'Custom content injected to the page before any script',
    category: HEADER,
  },
  {
    name: 'BODY_CUSTOM_CONTENT',
    type: 'string',
    default: '',
    description: 'Custom content injected into the page within the body tag',
    category: HEADER,
  },
  {
    name: 'SHOW_MY_BOOKINGS',
    type: 'bool',
    default: true,
    description: 'Show "My Bookings" option on the avatar dropdown',
    category: HEADER,
  },
  {
    name: 'SHOW_MY_ACCOUNT',
    type: 'bool',
    default: true,
    description: 'Show "My Account" option on the avatar dropdown',
    category: HEADER,
  },
  {
    name: 'HEADER_USER_NAME',
    type: 'select',
    options: [
      { title: 'Email', value: 'email' },
      { title: 'Name', value: 'name' },
    ],
    default: 'email',
    description: 'Customize the user name displayed on the header',
    category: HEADER,
  },
  {
    name: 'HEADER_SHOW_TA_SUBTEXT',
    type: 'bool',
    default: true,
    description: 'Show Travel Agent subtext on the header',
    category: HEADER,
  },
];
