import _ from 'lodash';
import { getHistory, getLocaleFreePathname } from 'app/history';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { call, select } from 'redux-saga/effects';
import { handleRefParam } from 'app/sagas/utils';
import {
  getActiveLanguage,
  getCurrency,
  getLocation,
  getLoggedIn,
  getSavedSearch,
} from 'app/sagas/selectors';
import config from 'config';
import posthog from 'posthog-js';

const languageToOldFormat = (language) =>
  language?.replace(/(_.*$)/, (match) => match.replace('_', '-').toLowerCase());

const allowedParams = [
  'ta',
  'wl',
  'staging_api',
  'testing_api',
  'gid',
  'guid',
  'airline',
  'airline2',
  'flight_number',
  'flight_number2',
  'customer_special_instructions',
  'partner_tracking_id',
  'coupon_code',
];

export default function* doBookTrip({
  payload: {
    session_id,
    trip_id,
    ondemand,
    vehicle_type,
    isPointToPoint,
    isAirport,
  },
}) {
  const currency = yield select(getCurrency);
  const location = yield select(getLocation);
  const ref = yield call(handleRefParam);
  const query = _.pick(location.query, allowedParams);
  const isLoggedin = yield select(getLoggedIn);
  const activeLanguage = yield select(getActiveLanguage);

  let params = { session_id, trip_id, currency, ...query, ...ref };
  if (ondemand) params.ondemand = 1;

  const { customer_id } = yield select(getSavedSearch);
  if (customer_id) {
    params = { ...params, customer_id };
  }

  // In our spagetti sagas code we call `doBookTrip` twise
  // when the user click to the "book" button on the search results,
  // which push to history "/book" page two times. In that case, when the
  // user click "back" button, he won't go to the search results page
  // To DIRTY fix that, we use "replace" when the page is already "/book"
  const history = yield call(getHistory);
  const pathname = getLocaleFreePathname(history.location.pathname);

  const isVehicleSedan = [
    'Private Van',
    'Sedan',
    'Limo',
    'SUV',
    'Taxi',
  ].includes(vehicle_type);
  const isLanguageEnglish = activeLanguage === 'en_US';
  const activeLanguageOldFormat = languageToOldFormat(activeLanguage);
  const isUserGuest = !isLoggedin;
  const isLoyaltyProgramDisabled = !config.LOYALTY_PROGRAM_ENABLED;
  const isCustomBookingDomainDisabled = !config.CUSTOM_BOOKING_DOMAIN;

  const conditions = {
    isVehicleSedan,
    isLanguageEnglish,
    isUserGuest,
    isLoyaltyProgramDisabled,
    isPointToPoint,
    isCustomBookingDomainDisabled,
  };

  const iteration2Conditions = {
    isUserGuest,
    isVehicleSedan,
    isLanguageEnglish,
    isLoyaltyProgramDisabled,
    isAirport,
    isCustomBookingDomainDisabled,
  };

  const shouldRedirectToNewCheckout = (conditions) =>
    Object.values(conditions).every(Boolean);

  const redirectToNewCheckout = () => {
    const newCheckoutUrl = `/v2/${activeLanguageOldFormat}/book?session_id=${session_id}&trip_id=${trip_id}&currency=${currency}&fromPath=${encodeURIComponent(
      btoa(window.location.href)
    )}`;
    window.location.href = newCheckoutUrl;
  };

  if (shouldRedirectToNewCheckout(conditions)) {
    if (posthog.getFeatureFlag('checkout-iteration-1') === 'point-to-point') {
      yield call(redirectToNewCheckout);
      return;
    }
  }

  if (shouldRedirectToNewCheckout(iteration2Conditions)) {
    if (posthog.getFeatureFlag('checkout-iter-2') === 'test') {
      yield call(redirectToNewCheckout);
      return;
    }
  }

  const updateFunc = pathname === '/book' ? history.replace : history.push;
  yield call(updateFunc, `/book${objectToQueryString(params)}`);
}
